import {AutocompleteElement, FormContainer, SelectElement, TextFieldElement, useFormContext} from "react-hook-form-mui";
import {Button, Stack} from "@mui/material";
import {DevTool} from "@hookform/devtools";
import React, {useEffect} from "react";
import {CompanyDataService, ContactDataService} from "../../../data";
import {useQuery} from "@tanstack/react-query";
import Swal from "sweetalert2";

const ContactFormComponent = ({contactId, editMode, onSuccess, onError, prefilledDefaults}) => {
    const defValues = {
        id: 0,
        name: "",
        company: "",
        position: "",
        phone: "",
        email: "",
        status: ""
    }

    const {data: companyData, isLoading: isCompanyLoading, error: companyError} = useQuery({
        queryKey: ["companyData"],
        queryFn: async () => (await CompanyDataService.fetchAll()).data.map(company => ({
            id: company.id,
            label: company.name
        }))
    });

    const {data: statusData, isLoading: isStatusLoading, error: statusError} = useQuery({
        queryKey: ["statusData"],
        queryFn: async () => (await ContactDataService.Status.fetchAll()).data.map(status => ({id: status.id, label: status.caption}))
    })

    const loadDefaultValues = async () => {
        if (!editMode) {
            if(prefilledDefaults){
                Object.keys(prefilledDefaults).forEach((key, index) => {
                    defValues[key] = prefilledDefaults[key];
                });
            }
            return defValues;
        }

        let {data} = await ContactDataService.getById(contactId);
        return {
            id: data.id,
            name: data.name,
            company: data.company,
            position: data.position,
            phone: data.phone,
            email: data.email,
            status: data.status,
        };
    }

    const callOnSuccess = (data) => {
        if (onSuccess)
            onSuccess(data);
    }

    const callOnError = (data) => {
        if (onError)
            onError(data);
    }

    const onSubmit = (data) => {
        if (editMode) {
            ContactDataService.put(data)
                .then(response => {
                    callOnSuccess(response.data);
                    handleSuccess();
                })
                .catch(error => {
                    callOnError(error);
                    handleError();
                });
        } else {
            ContactDataService.post(data)
                .then(response => {
                    callOnSuccess(response.data);
                    handleSuccess();
                })
                .catch(error => {
                    callOnError(error);
                    handleError();
                });
        }
    }

    const handleSuccess = (data) => {
        console.log(data);
        Swal.fire({
            title: "Kontakt erfolgreich gespeichert",
            icon: "success"
        })
    }

    const handleError = (data) => {
        Swal.fire({
            title: "Kontakt konnte nicht gespeichert werden",
            icon: "error"
        })
    }

    const FormComponent = () => {
        const {reset, control} = useFormContext();

        useEffect(() => {
            reset();
        }, [contactId]);

        return (
            <>
                <Stack direction={"column"} alignItems={"flex-start"} spacing={2}>
                    <TextFieldElement name="name" label="Name" fullWidth/>
                    <AutocompleteElement name="company" label="Firma" autocompleteProps={{fullWidth: true}}
                                         textFieldProps={{fullWidth: true}} options={companyData ?? []}
                                         loading={isCompanyLoading} matchId/>
                    <TextFieldElement name="position" label="Position" fullWidth/>
                    <TextFieldElement name="phone" label="Telefon" fullWidth/>
                    <TextFieldElement name="email" label="Mail" fullWidth/>
                    <SelectElement name="status" label="Status" options={statusData} fullWidth/>
                    <Button type={"submit"} variant="contained" color="primary">Speichern</Button>
                </Stack>
                <DevTool control={control}/>
            </>
        )
    }

    return (
        <>
            <FormContainer defaultValues={loadDefaultValues} onSuccess={onSubmit}>
                <FormComponent/>
            </FormContainer>
        </>
    )
}

export default ContactFormComponent;
