import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import 'rsuite/dist/rsuite.min.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import keycloak from "./keycloak";
import {ReactKeycloakProvider} from "@react-keycloak/web";
import {
    QueryClient,
    QueryClientProvider,
} from '@tanstack/react-query'
import {ReactQueryDevtools} from '@tanstack/react-query-devtools'
import axios from 'axios';

const root = ReactDOM.createRoot(document.getElementById('root'));
const initOption = {
    onLoad: "login-required",
    checkLoginIframe: false
};
const queryClient = new QueryClient()

axios.interceptors.request.use(async (config) => {
    // Token exists, refresh it
    await keycloak.updateToken(5);
    // Modify Axios config
    config.headers = {
        ...config.headers,
        Authorization: `Bearer ${keycloak.token}`,
        "Access-Control-Allow-Origin": "*",
    };
    return config;
}, (error) => {
    console.log('Failed to refresh token with error ' + error.message);
});


root.render(
        <ReactKeycloakProvider initOptions={initOption} authClient={keycloak}>
            <React.StrictMode>
                <QueryClientProvider client={queryClient}>
                    <App/>
                    <ReactQueryDevtools initialIsOpen={false}/>
                </QueryClientProvider>
            </React.StrictMode>
        </ReactKeycloakProvider>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
