import DashboardIcon from "@rsuite/icons/legacy/Dashboard";
import GlobalIcon from "@rsuite/icons/Global";
import UserRoles from "./UserRoles.json";
import PeoplesIcon from "@rsuite/icons/Peoples";
import * as React from "react";

const ConfiguredRoutes = [
    {
        navLink: '/',
        icon: <DashboardIcon/>,
        iconMui: <DashboardIcon/>,
        caption: "Dashboard",
        roles: [UserRoles.Administrator, UserRoles.Member]
    },
    {
        navLink: "/companies",
        icon: <GlobalIcon/>,
        iconMui: <GlobalIcon/>,
        caption: "Firmen",
        children: null,
        roles: [UserRoles.Administrator]
    },
    {
        navLink: "/contacts",
        icon: <PeoplesIcon/>,
        iconMui: <PeoplesIcon/>,
        caption: "Kontakte",
        children: null,
        roles: [UserRoles.Administrator]
    },
    {
        navLink: "/leads",
        icon: <PeoplesIcon/>,
        iconMui: <PeoplesIcon/>,
        caption: "Leads",
        children: null,
        roles: [UserRoles.Administrator]
    },

]

export {ConfiguredRoutes};
