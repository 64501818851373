import {FormContainer, SelectElement, TextFieldElement, useFormContext} from "react-hook-form-mui";
import {Button, Stack} from "@mui/material";
import {DevTool} from "@hookform/devtools";
import React, {useEffect} from "react";
import {CompanyDataService, CountryDataService} from "../../../data";
import {useQuery} from "@tanstack/react-query";

const CompanyFormComponent = ({companyId, editMode, onSuccess, onError}) => {
    const defValues = {
        id: 0,
        name: "",
        address: "",
        zip: "",
        city: "",
        country: "",
        employees: ""
    }

    const {data: countryData, isLoading: isCountryLoading, error} = useQuery({
        queryKey: ["countryData"],
        queryFn: async () => (await CountryDataService.fetchAll()).data.map(country => ({id: country.code, label: country.caption}))
    })

    const loadDefaultValues = async () => {
        console.log("Loading default values");

        if (!editMode) return defValues;

        let {data} = await CompanyDataService.getById(companyId);
        return {
            id: data.id,
            name: data.name,
            address: data.address,
            zip: data.zip,
            city: data.city,
            country: data.country,
            employees: data.employees
        };
    }

    const callOnSuccess = (data) => {
        if(onSuccess)
            onSuccess(data);
    }

    const callOnError = (data) => {
        if(onError)
            onError(data);
    }

    const onSubmit = (data) => {
        if (editMode) {
            CompanyDataService.put(data).then(value => callOnSuccess(value)).catch(reason => callOnError(reason));
        } else {
            CompanyDataService.post(data).then(value => callOnSuccess(value)).catch(reason => callOnError(reason));
        }
    }

    const FormComponent = () => {
        const {reset, control} = useFormContext();

        useEffect(() => {
            reset();
        }, [companyId]);

        return (
            <>
                <Stack direction={"column"} alignItems={"flex-start"} spacing={2}>
                    <TextFieldElement name="name" label="Name" fullWidth/>
                    <TextFieldElement name="address" label="Adresse" fullWidth/>
                    <TextFieldElement name="zip" label="PLZ" fullWidth/>
                    <TextFieldElement name="city" label="Stadt" fullWidth/>
                    <SelectElement name="country" label="Land" options={countryData} fullWidth/>
                    <TextFieldElement name="employees" label="Mitarbeiter" fullWidth/>
                    <Button type={"submit"} variant="contained" color="primary">Speichern</Button>
                </Stack>
                <DevTool control={control}/>
            </>
        )
    }

    return (
        <>
            <FormContainer defaultValues={loadDefaultValues} onSuccess={onSubmit}>
                <FormComponent/>
            </FormContainer>
        </>
    )
}

export default CompanyFormComponent;