import {useParams} from "react-router-dom";
import {useQuery} from "@tanstack/react-query";
import React, {useState} from "react";

import {
    Grid,
    Stack,
    Dialog,
    DialogTitle,
    DialogContent,
    Button,
    DialogActions,
    CircularProgress,
    Box
} from "@mui/material";
import AddIcon from '@mui/icons-material/Add';
import "./assets/Details.scss";

import JsonView from "@uiw/react-json-view";
import {ContactDataService} from "../../data";
import {ContactFormComponent, LeadFormComponent} from "../../components/Forms";
import {AttributeDisplay, CrmTable} from "../../components/Controls";
import LeadStatus from "../../data/LeadStatus";


export default function ContactDetail() {
    const {contactId} = useParams();
    const [dialogOpen, setDialogOpen] = useState(false);
    const [dialogTitle, setDialogTitle] = useState(null);
    const [dialogContent, setDialogContent] = useState(<></>);

    const {data: contactData, error, isLoading} = useQuery({
        queryKey: [contactId],
        queryFn: async () => {
            const result = await ContactDataService.getById(contactId);
            console.log(result.data);
            return result.data;
        },
    });


    if (isLoading || !contactData || !contactData.companyNavigation) return (
        <Box sx={{display: 'flex'}}>
            <CircularProgress/>
        </Box>
    );
    if (error) return <div>Fehler: {error.message}</div>;


    const leadHeaders = [
        {id: 'id', label: 'Id', align: 'left'},
        {id: 'shortDescription', label: 'Kurzbeschreibung', align: 'left'},
        {id: 'dateEntered', label: 'Eingegangen am', align: 'left'},
        {id: 'leadSource.caption', label: 'Quelle des Leads', align: 'left'},
    ];


    const openLeads = contactData.companyNavigation.leads.filter(lead => [LeadStatus.New, LeadStatus.Contacted, LeadStatus.Qualified].includes(lead.leadStatusId));
    const closed = contactData.companyNavigation.leads.filter(lead => [LeadStatus.Converted].includes(lead.leadStatusId));
    const lostLeads = contactData.companyNavigation.leads.filter(lead => [LeadStatus.Lost, LeadStatus.NotConverted].includes(lead.leadStatusId));

    const handleClose = () => {
        setDialogTitle(null);
        setDialogOpen(false);
        setDialogContent(<></>);
    };

    const DetailsDialog = () => {
        return (
            <Dialog open={dialogOpen} onClose={handleClose} fullWidth={true} maxWidth={"lg"}>
                <DialogTitle>{dialogTitle}</DialogTitle>
                <DialogContent>
                    <div style={{padding: "0.5em 0"}}>
                        {dialogContent}
                    </div>
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleClose}>Close</Button>
                </DialogActions>
            </Dialog>
        )
    }

    const handleNewLead = () => {
        setDialogTitle("Neuer Lead");
        setDialogContent(
            <LeadFormComponent editMode={false}
                               onSuccess={handleClose}
                               onError={handleClose}
                               prefilledDefaults={{contactId: Number.parseInt(contactId)}}
            />
        );
        // Show the dialog
        setDialogOpen(true);
    }

    const handleNewContact = () => {
        setDialogTitle("Neuer Kontakt");
        setDialogContent(
            <ContactFormComponent editMode={false}
                                  onSuccess={handleClose}
                                  onError={handleClose}
                                  prefilledDefaults={{contact: Number.parseInt(contactId)}}
            />
        );
        // Show the dialog
        setDialogOpen(true);
    }

    const handleShowDebug = () => {
        setDialogTitle("Debug")
        setDialogOpen(true);
        setDialogContent(<JsonView value={contactData}/>)
    }

    return (
        <>
            <Stack direction={"column"} alignItems={"flex-start"} spacing={2}>
                <h1>Übersicht {contactData.name}</h1>
                <div className={"table-container"}>
                    <Grid container spacing={2}>
                        <Grid item xs={8} sm={6}>
                            <AttributeDisplay attr={"Email"} value={contactData.email}/>
                            <AttributeDisplay attr={"Telefon"} value={contactData.phone}/>
                            <AttributeDisplay attr={"Status"} value={contactData.statusNavigation.caption}/>
                        </Grid>
                        <Grid item xs={4} sm={3}>
                            <Stack direction={"column"} alignItems={"flex-start"}>
                                <AttributeDisplay attr={"Firma"} value={contactData.companyNavigation.name}/>
                                <AttributeDisplay attr={"Position"} value={contactData.position}/>
                                <AttributeDisplay attr={"Leads"} value={contactData.companyNavigation.leads.length}/>
                            </Stack>
                        </Grid>
                    </Grid>
                </div>
                <Stack direction={"row"} spacing={2}>
                    <Button startIcon={<AddIcon/>} variant={"contained"} color={"primary"} onClick={handleNewLead}>
                        Neuer Lead
                    </Button>
                    <Button startIcon={<AddIcon/>} variant={"contained"} color={"success"} onClick={handleNewContact}>
                        Neuer Kontakt
                    </Button>
                </Stack>
                <div className={"table-container"}>
                    <Grid container spacing={2}>
                        <Grid item className={"table-container-item"} sm={12} md={12} lg={6}>
                            <CrmTable
                                title={"Offene Leads"}
                                fallbackText={"Keine Leads vorhanden"}
                                data={openLeads}
                                idField={"id"}
                                headers={leadHeaders}
                                showIconButtons={false}
                            />
                        </Grid>
                        <Grid item className={"table-container-item"} sm={12} md={12} lg={6}>
                            <CrmTable
                                title={"Abgeschlossen"}
                                fallbackText={"Keine Leads vorhanden"}
                                data={closed}
                                idField={"id"}
                                headers={leadHeaders}
                                showIconButtons={false}
                            />
                        </Grid>
                        <Grid item className={"table-container-item"} sm={12} md={12} lg={6}>
                            <CrmTable
                                title={"Verloren"}
                                fallbackText={"Keine Leads vorhanden"}
                                data={lostLeads}
                                idField={"id"}
                                headers={leadHeaders}
                                showIconButtons={false}
                            />
                        </Grid>
                    </Grid>
                </div>
            </Stack>

            <Button onClick={handleShowDebug}>Show Data</Button>

            <DetailsDialog/>
        </>
    );
}
