import * as React from 'react';
import './PritzITAppBar.scss';
import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import Container from '@mui/material/Container';
import logo from './assets/logo.png';
import {Avatar} from "@mui/material";
import {useKeycloak} from "@react-keycloak/web";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import {NavLink} from "react-router-dom";

export default function PritzITAppBar() {
    const {keycloak, initialized} = useKeycloak();
    const [anchorElUser, setAnchorElUser] = React.useState(null);

    const settings = [
        {
            caption: 'Logout',
            eventKey: () => keycloak.logout()
        }
    ];

    function getKeycloakUserInitials() {
        if (initialized)
            return keycloak?.authenticated ? keycloak?.idTokenParsed?.name?.split(' ').map((name) => name[0]).join('') : '';
    }


    const handleOpenUserMenu = (event) => {
        setAnchorElUser(event.currentTarget);
    };

    const handleCloseUserMenu = () => {
        setAnchorElUser(null);
    };


    return (
        <AppBar position="static" className={"app-bar"} style={{backgroundColor: "#d6edf3"}}>
            <Container maxWidth={false}>
                <Toolbar disableGutters style={{justifyContent: "space-between"}}>
                    <NavLink to={"/"}>
                        <img src={logo} alt="Logo" className='logo'/>
                    </NavLink>
                    <Avatar onClick={handleOpenUserMenu} sx={{
                        width: {sm: 56},
                        height: {sm: 56}
                    }}>
                        {getKeycloakUserInitials()}
                    </Avatar>
                </Toolbar>
            </Container>
            <Menu
                anchorEl={anchorElUser}
                open={!!anchorElUser}
                onClose={handleCloseUserMenu}
            >
                {settings.map((option) => (
                    <MenuItem
                        key={option.caption}
                        onClick={option.eventKey}
                    >
                        {option.caption}
                    </MenuItem>
                ))}
            </Menu>
        </AppBar>
    );
}
