import React from "react";
import {Card, CardContent, Stack, Typography} from "@mui/material";
import {useNavigate} from "react-router-dom";

const DashboardCard = ({ title, subheader, body, avatar, color, navTo }) => {
    const navigate = useNavigate()
    const navigateTo = () => {
        if(navTo)
            navigate(navTo);
    }

    return (
        <Card sx={{
            display: "flex",
            flexDirection: "column",
            padding: 2,
            boxShadow: 6,
            minHeight: "150px",
            justifyContent: "space-between",
            backgroundColor: color,
            cursor: navTo ? "pointer" : "unset"
        }}
              onClick={navigateTo}
        >
            <Stack
                direction="row"
                justifyContent="center"
            >
                {avatar}
                <Typography variant="h6" sx={{ fontSize: "18pt", marginLeft: 1 }}>
                    {title}
                </Typography>
            </Stack>
            <Typography
                variant="h4"
                sx={{ fontSize: "36pt", fontWeight: "bold", color: "primary.main", textAlign: "center" }}
            >
                {subheader}
            </Typography>

            {body && (
                <CardContent>
                    <Typography variant="body1">{body}</Typography>
                </CardContent>
            )}
        </Card>
    );
};

export default DashboardCard;
