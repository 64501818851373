import {useKeycloak} from "@react-keycloak/web";
import PageLayout from "./components/layout/PageLayout";
import {BrowserRouter, Outlet, Route, Routes} from "react-router-dom";
import './App.css';

import {
    CompanyForm,
    CompanyOverview,
    ContactForm,
    ContactsOverview,
    LeadForm,
    LeadsOverview,
    StatusOverview,
    ContactDetail,
    CompanyDetail, Dashboard
} from "./pages";
import LeadDetail from "./pages/Detail/LeadDetail";

export default function App() {
    const {keycloak, initialized} = useKeycloak();

    function buildRoutes() {
        return (
            <>
                <Route index path={"/"} element={<Dashboard/>}/>
                <Route path={"/companies"} element={<CompanyOverview/>}/>
                <Route path={"/companies"} >
                    <Route path={"new"} index element={<CompanyForm editMode={false}/>}/>
                    <Route path={"edit/:companyId"}  element={<CompanyForm editMode={true}/>}/>
                    <Route path={"show/:companyId"}  element={<CompanyDetail/>}/>
                </Route>
                <Route path={"/contacts"} element={<ContactsOverview/>}/>
                <Route path={"/contacts"} >
                    <Route path={"new"} index element={<ContactForm editMode={false}/>}/>
                    <Route path={"edit/:contactId"} element={<ContactForm editMode={true}/>}/>
                    <Route path={"show/:contactId"} element={<ContactDetail/>}/>
                </Route>
                <Route path={"/leads"} element={<LeadsOverview/>}/>
                <Route path={"/leads"}>
                    <Route path={"new"} element={<LeadForm editMode={false}/>} />
                    <Route path={"edit/:leadId"} element={<LeadForm editMode={true}/>} />
                    <Route path={"show/:leadId"} element={<LeadDetail/>} />
                </Route>
            </>
        );
    }

    return (
        <BrowserRouter>
            {initialized && (
                <>
                    <PageLayout>
                        <Routes>
                            {buildRoutes()}
                        </Routes>
                        <Outlet/>
                    </PageLayout>
                </>
            )}
        </BrowserRouter>
    );
}
