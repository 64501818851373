import {AttributeDisplay, CrmTable} from "../../components/Controls";
import {useParams} from "react-router-dom";
import {useQuery} from "@tanstack/react-query";
import React, {useState} from "react";
import {CompanyDataService, ContactDataService} from "../../data";
import {
    Grid,
    Stack,
    Dialog,
    DialogTitle,
    DialogContent,
    Button,
    DialogActions,
    Box,
    CircularProgress
} from "@mui/material";
import AddIcon from '@mui/icons-material/Add';
import "./assets/Details.scss";
import {ContactFormComponent, LeadFormComponent} from "../../components/Forms";
import JsonView from "@uiw/react-json-view";
import LeadStatus from "../../data/LeadStatus";


export default function CompanyDetail() {
    const {companyId} = useParams();
    const [dialogOpen, setDialogOpen] = useState(false);
    const [dialogTitle, setDialogTitle] = useState(null);
    const [dialogContent, setDialogContent] = useState(<></>);

    const {data: companyData, error, isLoading} = useQuery({
        queryKey: [companyId],
        queryFn: async () => (await CompanyDataService.getById(companyId)).data,
    });

    const {data: contactTableData, contactError, isContactLoading} = useQuery({
        queryKey: ["contactData"],
        queryFn: async () => (await ContactDataService.getByCompanyId(companyId)).data,
        enabled: !!companyData,
    });

    if (isLoading || isContactLoading) return
    <Box sx={{display: 'flex'}}>
        <CircularProgress/>
    </Box>;
    if (error || contactError) return <div>Fehler: {error ? error.message : contactError.message}</div>;





    const leadHeaders = [
        {id: 'id', label: 'Id', align: 'left'},
        {id: 'shortDescription', label: 'Kurzbeschreibung', align: 'left'},
        {id: 'dateEntered', label: 'Eingegangen am', align: 'left'},
        {id: 'leadSource.caption', label: 'Quelle des Leads', align: 'left'},
    ];

    const contactHeaders = [
        {id: 'id', label: 'Id', align: 'left'},
        {id: 'name', label: 'Name', align: 'left'},
        {id: 'position', label: 'Position', align: 'left'},
        {id: 'phone', label: 'Phone', align: 'left'},
        {id: 'email', label: 'Email', align: 'left'},
    ];

    const openLeads = companyData?.leads?.filter(lead => [LeadStatus.New, LeadStatus.Contacted, LeadStatus.Qualified].includes(lead.leadStatusId)) || [];
    const closed = companyData?.leads?.filter(lead => [LeadStatus.Converted].includes(lead.leadStatusId)) || [];
    const lostLeads = companyData?.leads?.filter(lead => [LeadStatus.Lost, LeadStatus.NotConverted].includes(lead.leadStatusId)) || [];

    const handleClose = () => {
        setDialogTitle(null);
        setDialogOpen(false);
        setDialogContent(<></>);
    };

    const DetailsDialog = () => {
        return (
            <Dialog open={dialogOpen} onClose={handleClose} fullWidth={true} maxWidth={"lg"}>
                <DialogTitle>{dialogTitle}</DialogTitle>
                <DialogContent>
                    <div style={{padding: "0.5em 0"}}>
                        {dialogContent}
                    </div>
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleClose}>Close</Button>
                </DialogActions>
            </Dialog>
        )
    }

    const handleNewLead = () => {
        setDialogTitle("Neuer Lead");
        setDialogContent(
            <LeadFormComponent editMode={false}
                               onSuccess={handleClose}
                               onError={handleClose}
                               prefilledDefaults={{companyId: Number.parseInt(companyId)}}
            />
        );
        // Show the dialog
        setDialogOpen(true);
    }

    const handleNewContact = () => {
        setDialogTitle("Neuer Kontakt");
        setDialogContent(
            <ContactFormComponent editMode={false}
                               onSuccess={handleClose}
                               onError={handleClose}
                               prefilledDefaults={{company: Number.parseInt(companyId)}}
            />
        );
        // Show the dialog
        setDialogOpen(true);
    }

    const handleShowDebug = () => {
        setDialogTitle("Debug")
        setDialogOpen(true);
        setDialogContent(<JsonView value={contactTableData}/>)
    }

    return (
        <>
            <Stack direction={"column"} alignItems={"flex-start"} spacing={2}>
                <h1>Übersicht {companyData.name}</h1>
                <div className={"table-container"}>
                    <Grid container spacing={2}>
                        <Grid item xs={8} sm={6}>
                            <AttributeDisplay
                                attr={"Adresse"}
                                value={`${companyData.address}\n${companyData.zip} ${companyData.city}\n${companyData.countryNavigation?.caption || ''}`}
                            />
                        </Grid>
                        <Grid item xs={4} sm={3}>
                            <Stack direction={"column"} alignItems={"flex-start"}>
                                <AttributeDisplay attr={"Mitarbeiter"} value={companyData.employees}/>
                                <AttributeDisplay attr={"Leads"} value={companyData?.leads?.length || 0}/>
                            </Stack>
                        </Grid>
                    </Grid>
                </div>
                <Stack direction={"row"} spacing={2}>
                    <Button startIcon={<AddIcon/>} variant={"contained"} color={"primary"} onClick={handleNewLead}>
                        Neuer Lead
                    </Button>
                    <Button startIcon={<AddIcon/>} variant={"contained"} color={"success"} onClick={handleNewContact}>
                        Neuer Kontakt
                    </Button>
                </Stack>
                <div className={"table-container"}>
                    <Grid container spacing={2}>
                        <Grid item className={"table-container-item"} sm={12} md={12} lg={6}>
                            <CrmTable
                                title={"Offene Leads"}
                                fallbackText={"Keine Leads vorhanden"}
                                data={openLeads}
                                idField={"id"}
                                headers={leadHeaders}
                                showIconButtons={false}
                            />
                        </Grid>
                        <Grid item className={"table-container-item"} sm={12} md={12} lg={6}>
                            <CrmTable
                                title={"Abgeschlossen"}
                                fallbackText={"Keine Leads vorhanden"}
                                data={closed}
                                idField={"id"}
                                headers={leadHeaders}
                                showIconButtons={false}
                            />
                        </Grid>
                        <Grid item className={"table-container-item"} sm={12} md={12} lg={6}>
                            <CrmTable
                                title={"Verloren"}
                                fallbackText={"Keine Leads vorhanden"}
                                data={lostLeads}
                                idField={"id"}
                                headers={leadHeaders}
                                showIconButtons={false}
                            />
                        </Grid>
                        <Grid item className={"table-container-item"} sm={12}>
                            <CrmTable
                                title={"Kontakte"}
                                idField={"id"}
                                headers={contactHeaders}
                                data={contactTableData ?? []}
                                showIconButtons={false}
                            />
                        </Grid>
                    </Grid>
                </div>
            </Stack>

            <Button onClick={handleShowDebug}>Show Data</Button>

            <DetailsDialog/>
        </>
    );
}
