import axios from "axios";

const baseLink = process.env.REACT_APP_API_BASE_URL;
const contactEndpoint = baseLink + "/api/Contact";
const contactStatusEndpoint = baseLink + "/api/ContactStatus";

const ContactDataService = {
    fetchAll: async () => {
        return await axios.get(contactEndpoint);
    },
    getById: async (id) => {
        return await axios.get(`${contactEndpoint}/${id}`);
    },
    getByCompanyId: async (id) => {
        return await axios.get(`${contactEndpoint}/company/${id}`);
    },
    post: async (data) =>{
        return await axios.post(contactEndpoint, data);
    },
    put: async (data) => {
        return await axios.put(`${contactEndpoint}/${data.id}`, data);
    },
    Status:{
        fetchAll: async () => {
            return await axios.get(contactStatusEndpoint);
        },
        getById: async (id) => {
            return await axios.get(`${contactStatusEndpoint}/${id}`);

        }
    }
}

export {ContactDataService};
