import axios from "axios";

const baseLink = process.env.REACT_APP_API_BASE_URL;
const endpoint = baseLink + "/api/Lead";
const statusEndpoint = baseLink + "/api/LeadStatus";
const sourceEndpoint = baseLink + "/api/LeadSource";

const LeadDataService = {
    fetchAll: async () => {
        return await axios.get(endpoint);
    },
    getById: async (id) => {
        return await axios.get(`${endpoint}/${id}`);
    },
    post: async (data) =>{
        return await axios.post(endpoint, data);
    },
    put: async (data) => {
        return await axios.put(`${endpoint}/${data.id}`, data);
    },
    Status:{
        fetchAll: async () => {
            return await axios.get(statusEndpoint);
        },
        getById: async (id) => {
            return await axios.get(`${statusEndpoint}/${id}`);

        }
    },
    Source:{
        fetchAll: async () => {
            return await axios.get(sourceEndpoint);
        },
        getById: async (id) => {
            return await axios.get(`${sourceEndpoint}/${id}`);

        }
    }
}

export {LeadDataService};
