import {Box, Stack} from "@mui/material";
import PritzITAppBar from "./PritzITAppBar";
import * as React from "react";
import AdminNavigation from "../AdminNavigation";


export default function PageLayout(props) {


    return (
        <>
            <PritzITAppBar/>
            <Stack direction={"row"} className={"App-Container"} sx={{flexDirection: {xs: "column-reverse", sm: "column-reverse", md: "row"}}}>
                <AdminNavigation />
                <Box style={{flexGrow: 1, padding: "1em", overflowY: "auto", maxHeight: "calc(100dvh - 68.5px)"}}>
                    {props.children}
                </Box>
            </Stack>
        </>
    )
        ;
}
