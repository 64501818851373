import React from 'react';
import Swal from "sweetalert2";
import {LeadFormComponent} from "../../components/Forms";
import {useParams} from "react-router-dom";

export default function LeadForm({editMode}) {
    const {leadId} = useParams();
    const handleSuccess = (data) => {
        console.log(data);
        Swal.fire({
            title: "Lead erfolgreich gespeichert",
            icon: "success"
        })
    }

    const handleError = (data) => {
        console.log(data)
        Swal.fire({
            title: "Lead konnte nicht gespeichert werden",
            icon: "error"
        })
    }

    return (
        <>
            <h1>Lead {editMode ? "bearbeiten" : "erstellen"}</h1>
            <LeadFormComponent editMode={editMode} leadId={leadId} onSuccess={handleSuccess}
                                  onError={handleError}/>
        </>
    );
}
