import axios from "axios";

const baseLink = process.env.REACT_APP_API_BASE_URL;
const endpoint = baseLink + "/api/Country";

const CountryDataService = {
    fetchAll: async () => {
        return await axios.get(endpoint);
    },
    getById: async (id) => {
        return await axios.get(`${endpoint}/${id}`);
    }
}

export {CountryDataService};
