import React from 'react';
import {useParams} from "react-router-dom";
import PropTypes from "prop-types";
import {CompanyFormComponent} from "../../components/Forms";
import Swal from "sweetalert2";

export default function CompanyForm({editMode}) {
    const {companyId} = useParams();

    const handleSuccess = (data) => {
        console.log(data);
        Swal.fire({
            title: "Firma erfolgreich gespeichert",
            icon: "success"
        })
    }

    const handleError = (data) => {
        Swal.fire({
            title: "Firma konnte nicht gespeichert werden",
            icon: "error"
        })
    }

    return (
        <>
            <h1>Firma {editMode ? "bearbeiten" : "erstellen"}</h1>
            <CompanyFormComponent editMode={editMode} companyId={companyId} onSuccess={handleSuccess} onError={handleError}/>
        </>
    );
}

CompanyForm.propTypes = {
    editMode: PropTypes.bool.isRequired
}
CompanyForm.defaultValues = {
    editMode: true
}


