import axios from "axios";

const baseLink = process.env.REACT_APP_API_BASE_URL;
const endpoint = baseLink + "/api/Company";

const CompanyDataService = {
    fetchAll: async () => {
        return await axios.get(endpoint);
    },
    getById: async (id) => {
        return await axios.get(`${endpoint}/${id}`);
    },
    post: async (data) =>{
        return await axios.post(endpoint, data);
    },
    put: async (data) => {
        return await axios.put(`${endpoint}/${data.id}`, data);
    }
}

export {CompanyDataService};
