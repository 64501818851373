import React from 'react';
import {CrmTable} from "../../components/Controls";
import {Box, Button, CircularProgress, Stack} from "@mui/material";
import {useNavigate} from "react-router-dom";
import {useQuery} from "@tanstack/react-query";
import {CompanyDataService} from "../../data";


const CompanyOverview = () => {
    const navigate = useNavigate();
    const {isPending, error, data} = useQuery(
        {
            queryKey: ['companies'],
            queryFn: async () =>
                (await CompanyDataService.fetchAll()).data
        });

    const headers = [
        {id: 'name', label: 'Name', align: 'left'},
        {id: 'city', label: 'Stadt', align: 'left'},
        {id: 'address', label: 'Adresse', align: 'left'},
        {id: 'country', label: 'Land', align: 'left'},
        {id: 'employees', label: 'Mitarbeiter', align: 'left'},
    ];

    const handleEdit = (data) => {
        navigate("/companies/edit/" + data.id);
    }
    const handleShow = (data) => {
        navigate("/companies/show/" + data.id);
        return data;
    }

    let content;

    if (isPending)
        content = (
            <Box sx={{display: 'flex'}}>
                <CircularProgress/>
            </Box>
        );
    else if (error)
        content = (<p>{error.message}</p>);
    else
        content = (
            <div className={"table-container"}>
                <div className={"table-container-item"}>
                    <CrmTable
                        idField={"id"}
                        data={data}
                        onEdit={handleEdit}
                        onShow={handleShow}
                        headers={headers}
                    />
                </div>
            </div>
        )
    return (
        <Stack direction={"column"} alignItems={"flex-start"} spacing={2}>
            <h1>Firmenübersicht</h1>
            <Button variant="contained" onClick={() => {
                navigate("new")
            }}>
                Neue Firma
            </Button>
            {content}
        </Stack>
    );
}

export default CompanyOverview;
