import React from 'react';
import { Typography, Grid } from '@mui/material';



const AttributeDisplay = ({attr, value}) => {


    return (
        <Grid container item xs={6} style={{marginBottom:"8px", marginTop:"8px"}}>
            <Grid item xs={12}>
                <Typography style={{fontWeight:"bold"}}>{attr}</Typography>
            </Grid>
            <Grid item xs={12}>
                {value ? (
                    value.toString().split("\n").map((val, index) => (
                        <Typography variant="body1" key={index}>
                            {val}
                        </Typography>
                    ))
                ) : (
                    <Typography variant="body1">-</Typography>
                )}
            </Grid>
        </Grid>
    );
}
export default AttributeDisplay;

