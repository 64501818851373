import React from 'react';
import {CrmTable} from "../../components/Controls";
import {Box, Button, CircularProgress, Stack} from "@mui/material";
import {useNavigate} from "react-router-dom";
import {useQuery} from "@tanstack/react-query";
import {ContactDataService} from "../../data";


const ContactOverview = () => {
    const navigate = useNavigate();
    const {isPending, error, data} = useQuery({
        queryKey: ['contacts'],
        queryFn: async () =>
            (await ContactDataService.fetchAll()).data
    });

    const headers = [
        {id: 'name', label: 'Name', align: 'left'},
        {id: 'email', label: 'E-Mail', align: 'left'},
        {id: 'phone', label: 'Telefon', align: 'left'},
        {id: 'companyNavigation.name', label: 'Firma', align: 'left'},
        {id: 'position', label: 'Position', align: 'left'},
        {id: 'createdBy', label: 'Erstellt von:', align: 'left'},
    ];

    const handleEdit = (data) => {
        navigate("/contacts/edit/" + data.id);
    }
    const handleShow = (data) => {
        navigate("/contacts/show/" + data.id);
        return data;
    }

    let content;

    if (isPending)
        content = (
            <Box sx={{display: 'flex'}}>
                <CircularProgress/>
            </Box>
        );
    else if (error)
        content = (<p>{error.message}</p>);
    else
        content = (
            <div className={"table-container"}>
                <div className={"table-container-item"}>
                    <CrmTable idField={"id"}
                              data={data}
                              onEdit={handleEdit}
                              onShow={handleShow}
                              headers={headers}
                    />
                </div>
            </div>)
    return (
        <Stack direction={"column"} alignItems={"flex-start"} spacing={2}>
            <h1>Kontaktübersicht</h1>
            <Button variant="contained" onClick={() => {
                navigate("new")
            }}>
                Neuer Kontakt
            </Button>
            {content}
        </Stack>
    );
}

export default ContactOverview;
