import {useKeycloak} from "@react-keycloak/web";
import PropTypes from "prop-types";

function AuthenticatedTemplate (props){
    const {keycloak, initialized} = useKeycloak();
    return (
        <>
            {(initialized && keycloak.authenticated) && props.children}
        </>
    );
}

function UnauthenticatedTemplate (props){
    const {keycloak, initialized} = useKeycloak();
    return (
        <>
            {(!initialized || !keycloak.authenticated) && props.children}
        </>
    );
}

/**
 * Renders the children components if the user possesses at least one of the specified roles.
 *
 * @param {Array} roles - An array of roles to check against the user's roles.
 * @param {JSX.Element} children - The components to render if the user possesses at least one of the specified roles.
 * @returns {JSX.Element} - The rendered components if the user possesses at least one of the specified roles.
 * @constructor
 */
function UserInRoleTemplate ({roles, children}){
    const {initialized, keycloak} = useKeycloak();

    const userRoles = keycloak.idTokenParsed.user_roles;
    const intersectedRoles = roles.filter(role => userRoles.includes(role.key));


    if(!initialized)
        return (<></>);

    if(intersectedRoles.length > 0){
        return <>
            {children}
        </>
    }
}
UserInRoleTemplate.propTypes = {
    roles: PropTypes.arrayOf(PropTypes.object),
    children: PropTypes.node
}

UserInRoleTemplate.defaultValues = {
    roles: []
}

export {AuthenticatedTemplate, UnauthenticatedTemplate, UserInRoleTemplate}