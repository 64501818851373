import {
    FormContainer,
    AutocompleteElement,
    SelectElement,
    TextareaAutosizeElement,
    TextFieldElement,
    useFormContext
} from "react-hook-form-mui";
import {Button, Stack} from "@mui/material";
import {DevTool} from "@hookform/devtools";
import React, {useEffect} from "react";
import {CompanyDataService, ContactDataService, LeadDataService} from "../../../data";
import {useQuery} from "@tanstack/react-query";
import Swal from "sweetalert2";

const LeadFormComponent = ({leadId, editMode, onSuccess, onError, prefilledDefaults}) => {
    const defValues = {
        id: 0,
        shortDescription: "",
        description: "",
        companyId: "",
        contactId: "",
        leadSourceId: "",
        dateEntered: null,
        leadStatusId: "",
    }

    //region QueryData
    const {data: contactData, isLoading: isContactLoading, error: contactError} = useQuery({
        queryKey: ["contactData"],
        queryFn: async () => (await ContactDataService.fetchAll()).data.map(contact => ({
            id: contact.id,
            label: `${contact.name} (${contact.companyNavigation?.name})`
        }))
    })

    const {data: companyData, isLoading: isCompanyLoading, error: companyError} = useQuery({
        queryKey: ["companyData"],
        queryFn: async () => (await CompanyDataService.fetchAll()).data.map(company => ({
            id: company.id,
            label: company.name
        }))
    })

    const {data: leadSourceData, isLoading: isLeadSourceLoading, error: leadSourceError} = useQuery({
        queryKey: ["leadSourceData"],
        queryFn: async () => (await LeadDataService.Source.fetchAll()).data.map(leadSource => ({
            id: leadSource.id,
            label: leadSource.caption
        }))
    })

    const {data: leadStatusData, isLoading: isLeadStatusLoading, error: leadStatusError} = useQuery({
        queryKey: ["leadStatusData"],
        queryFn: async () => (await LeadDataService.Status.fetchAll()).data.map(leadStatus => ({
            id: leadStatus.id,
            label: leadStatus.caption
        }))
    })
    //endregion QueryData

    const loadDefaultValues = async () => {
        console.log("Loading default values");

        if (!editMode) {
            if (prefilledDefaults) {
                Object.keys(prefilledDefaults).forEach((key, index) => {
                    defValues[key] = prefilledDefaults[key];
                });
            }
            return defValues;
        }

        let {data} = await LeadDataService.getById(leadId);
        return {
            id: data.id,
            shortDescription: data.shortDescription,
            description: data.description,
            companyId: data.companyId,
            contactId: data.contactId,
            dateEntered: data.dateEntered,
            leadSourceId: data.leadSourceId,
            leadStatusId: data.leadStatusId
        };
    }

    const callOnSuccess = (data) => {
        if (onSuccess)
            onSuccess(data);
    }

    const callOnError = (data) => {
        if (onError)
            onError(data);
    }

    const onSubmit = (data) => {
        if (editMode) {
            LeadDataService.put(data).then(value => {
                callOnSuccess(value);
                handleSuccess();
            })
                .catch(reason => {
                    callOnError(reason);
                    handleError();
                });
        } else {
            LeadDataService.post(data)
                .then(value => {
                    callOnSuccess(value);
                    handleSuccess();
                })
                .catch(reason => {
                    callOnError(reason);
                    handleError();
                });
        }
    }

    const handleSuccess = (data) => {
        console.log(data);
        Swal.fire({
            title: "Lead erfolgreich gespeichert",
            icon: "success"
        })
    }

    const handleError = (data) => {
        Swal.fire({
            title: "Lead konnte nicht gespeichert werden",
            icon: "error"
        })
    }

    const FormComponent = () => {
        const {reset, control} = useFormContext();

        useEffect(() => {
            reset();
        }, [leadId]);

        return (
            <>
                <Stack direction={"column"} alignItems={"flex-start"} spacing={2}>
                    <TextFieldElement name="shortDescription" label="Titel" fullWidth/>
                    <AutocompleteElement name="companyId"
                                         label="Firma"
                                         autocompleteProps={{fullWidth: true}}
                                         textFieldProps={{fullWidth: true}}
                                         options={companyData ?? []}
                                         loading={isCompanyLoading}
                                         matchId/>
                    <AutocompleteElement name="contactId" label="Kontakt" autocompleteProps={{fullWidth: true}}
                                         textFieldProps={{fullWidth: true}} options={contactData ?? []}
                                         loading={isContactLoading} matchId/>
                    <SelectElement name="leadSourceId" label="Quelle" options={leadSourceData} fullWidth/>
                    <SelectElement name="leadStatusId" label="Status" options={leadStatusData} fullWidth/>
                    <TextareaAutosizeElement name="description" label="Beschreibung" rows={8} fullWidth/>

                    <Button type={"submit"} variant="contained" color="primary">Speichern</Button>
                </Stack>
                <DevTool control={control}/>
            </>
        )
    }

    return (
        <>
            <FormContainer defaultValues={loadDefaultValues} onSuccess={onSubmit}>
                <FormComponent/>
            </FormContainer>
        </>
    )
}

export default LeadFormComponent;
