import {Nav, Sidenav} from "rsuite";
import {BottomNavigation, BottomNavigationAction, Box} from "@mui/material";
import * as React from "react";
import {useEffect, useState} from "react";
import {useLocation, useNavigate} from "react-router-dom";
import {UserInRoleTemplate} from "../base/Authentication";
import PropTypes from "prop-types";
import {ConfiguredRoutes} from '../../consts/NavigationRoutes';
import Paper from "@mui/material/Paper";


AdminNavigation.propTypes = {
    onSelect: PropTypes.func
}
export default function AdminNavigation({onSelect}) {
    const [activeKey, setActiveKey] = useState("/");
    const navigate = useNavigate();
    const location = useLocation();

    const NavigationItem = () => (
        <Nav key={"nav-admin"} activeKey={activeKey} onSelect={handleNavClick}>
            {navigation}
        </Nav>
    );

    function handleNavClick(eventKey) {
        if (eventKey) {
            setActiveKey(eventKey);
            navigate(eventKey);

            if (onSelect) {
                onSelect();
            }
        }
    }

    /**
     * Constructs navigation items or menus from a list of navigation objects.
     *
     * @param {Array} navList - The list of navigation objects.
     *
     * @returns {Array[JSX.Element]} - The constructed navigation items or menus.
     */
    const navigation = ConfiguredRoutes.map((nav, index) => {
        return (
            <>
                {constructItemOrMenu(nav, index)}
            </>
        );
    })

    /**
     * Constructs a navigation item element based on the provided navigation entry.
     *
     * @param {Object} navEntry - The navigation entry object.
     * @param index
     * @param {string} navEntry.navLink - The navigation link key.
     * @param {string} navEntry.icon - The icon to display for the navigation item.
     * @param {string} navEntry.caption - The caption to display for the navigation item.
     * @returns {JSX.Element} - The constructed navigation item element.
     */
    function constructItem(navEntry, index) {
        return (
            <Nav.Item key={index} eventKey={navEntry.navLink} icon={navEntry.icon}>
                {navEntry.caption}
            </Nav.Item>
        )
    }

    /**
     * Constructs a menu element based on the given navigation entry.
     *
     * @param {Object} navEntry - The navigation entry object.
     * @param {number} topIndex - The index of the menu element.
     *
     * @returns {JSX.Element} - The constructed menu element.
     */
    function constructMenu(navEntry, topIndex) {
        return (
            <Nav.Menu key={topIndex} title={navEntry.caption} icon={navEntry.icon}>
                {navEntry.children.map((navEntryChild, index) => constructItemOrMenu(navEntryChild, topIndex*10 + index))}
            </Nav.Menu>
        )
    }

    /**
     * Constructs a JSX element for a navigation item or menu based on the given parameters.
     *
     * @param {object} nav - The navigation object.
     * @param {number} index - The index of the navigation item or menu.
     * @returns {JSX.Element} - The constructed JSX element.
     */
    function constructItemOrMenu(nav, index) {
        if (nav.hasOwnProperty("roles")) {
            return (
                <UserInRoleTemplate key={"uirt-" + index} roles={nav.roles}>
                    {nav["children"] ? constructMenu(nav, index) : constructItem(nav, index)}
                </UserInRoleTemplate>
            );
        }

        if (nav["children"]) {
            return constructMenu(nav, index)
        }

        return constructItem(nav, index);
    }


    useEffect(() => {
        setActiveKey(location.pathname)
    }, []);


    return (
        <>
            <Box flexShrink={0} style={{height: "100%", overflowY: "auto"}}
                 sx={{display: {xs: "none", sm: "none", md: "block"}, width: {md: "240px"}}}>
                <Sidenav style={{height: "100%"}}>
                    <Sidenav.Body>
                        <NavigationItem key={"sidenav-body-navitem"}/>
                    </Sidenav.Body>
                </Sidenav>
            </Box>
            <Paper
                sx={{
                    display: {xs: "block", sm: "block", md: "none"},
                    zIndex: 100
                }}
                elevation={3}>
                <BottomNavigation
                    showLabels
                    value={activeKey}
                    onChange={(event, newValue) => {
                        handleNavClick(newValue);
                    }}
                >
                    {ConfiguredRoutes.map((route) => (
                        <BottomNavigationAction label={route.caption} value={route.navLink} icon={route.iconMui}
                                                key={"item-bna" + route.navLink}/>
                    ))}
                </BottomNavigation>
            </Paper>
        </>
    );
}
