import React from 'react';
import {Box, CircularProgress, Grid} from '@mui/material';
import {useQuery} from "@tanstack/react-query";
import {CompanyDataService, ContactDataService, LeadDataService, HealthDataService} from "../../data";
import {Business, People, Verified} from "@mui/icons-material";
import {DashboardCard} from "../../components/Controls";
import LeadStatus from "../../data/LeadStatus";
import DashboardCardColors from "../../components/Controls/DashboardControls/DashboardCardColors";
import {useNavigate} from "react-router-dom";

const Dashboard = () => {

    const {data: companyData, error: companyError, isLoading: isCompanyLoading} = useQuery({
        queryKey: ['companyData'],
        queryFn: async () => (await CompanyDataService.fetchAll()).data,
    });

    const {data: leadData, error: leadError, isLoading: isLeadLoading} = useQuery({
        queryKey: ['leadData'],
        queryFn: async () => (await LeadDataService.fetchAll()).data,
    });

    const {data: contactData, error: contactError, isLoading: isContactLoading} = useQuery({
        queryKey: ['contactData'],
        queryFn: async () => (await ContactDataService.fetchAll()).data,
    });

    if (companyError || leadError || contactError) return <div>Fehler...</div>;

    if (isCompanyLoading || isLeadLoading || isContactLoading) return (
        <Box sx={{display: 'flex'}}>
            <CircularProgress/>
        </Box>
    );

    const countLeadsByStatus = (leads, statuses) => {
        return leads.reduce((total, lead) => {
            if (statuses.includes(lead.leadStatus.id)) {
                return total + 1;
            } else {
                return total;
            }
        }, 0);
    }

    const openLeads = countLeadsByStatus(leadData, [LeadStatus.New, LeadStatus.Contacted, LeadStatus.Qualified]);
    const closedLeads = countLeadsByStatus(leadData, [LeadStatus.Converted]);
    const lostLeads = countLeadsByStatus(leadData, [LeadStatus.NotConverted, LeadStatus.Lost]);

    const RenderDashboardCard = (avatar, title, subheader, color, linkTo) => {
        return (
            <Grid item xs={12} md={4}>
                <DashboardCard
                    avatar={avatar}
                    title={title}
                    subheader={subheader}
                    color={color}
                    navTo={linkTo}
                />
            </Grid>
        );
    };

    return (
        <Grid container spacing={3} sx={{maxWidth: "1000px"}}>
            <Grid item xs={12}>
                <h1>Dashboard</h1>
            </Grid>
            {RenderDashboardCard(<People fontSize="large"/>, "Kontakte", contactData.length, undefined, "contacts")}
            {RenderDashboardCard(<Business fontSize="large"/>, "Firmen", companyData.length, undefined, "companies")}
            {RenderDashboardCard(<Verified fontSize="large"/>, "Leads Total", leadData.length, undefined, "leads")}
            {RenderDashboardCard(<Verified
                fontSize="large"/>, "Offene Leads", openLeads, DashboardCardColors.cardColorGreen)}
            {RenderDashboardCard(<Verified
                fontSize="large"/>, "Abgeschlossene Leads", closedLeads, DashboardCardColors.cardColorBlue)}
            {RenderDashboardCard(<Verified
                fontSize="large"/>, "Verlorene Leads", lostLeads, DashboardCardColors.cardColorYellow)}
        </Grid>
    );
}


export default Dashboard;
