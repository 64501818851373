import * as React from 'react';
import {useState} from 'react';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import TableSortLabel from '@mui/material/TableSortLabel';
import {Card, IconButton, Stack} from "@mui/material";
import VisibilityIcon from '@mui/icons-material/Visibility';
import EditNoteIcon from '@mui/icons-material/EditNote';
import Tooltip from "@mui/material/Tooltip";
import {useNavigate} from "react-router-dom";
import PropTypes from "prop-types";
import {flatten} from "flat";

export default function CrmTable({idField, fallbackText, data, headers, onEdit, onShow, showIconButtons, title}) {
    const navigate = useNavigate();
    const [orderDirection, setOrderDirection] = useState('asc');
    const [orderBy, setOrderBy] = useState('');

    const handleSort = (property) => {
        const isAsc = orderBy === property && orderDirection === 'asc';
        setOrderDirection(isAsc ? 'desc' : 'asc');
        setOrderBy(property);
    };

    const sortData = (data, orderBy, orderDirection) => {
        return data.sort((a, b) => {
            if (a[orderBy] < b[orderBy]) {
                return orderDirection === 'asc' ? -1 : 1;
            }
            if (a[orderBy] > b[orderBy]) {
                return orderDirection === 'asc' ? 1 : -1;
            }
            return 0;
        });
    };


    const sortedData = sortData([...data], orderBy, orderDirection);

    let tableContent = (<TableRow><TableCell colSpan={headers.length + 1}>{fallbackText ?? "Keine Daten"}</TableCell></TableRow>);

    if(sortedData.length)
        tableContent = (
            <>
                {sortedData.map((row, index) => (
                    <TableRow
                        key={index}
                        sx={{'&:last-child td, &:last-child th': {border: 0}}}
                    >
                        {headers.map((header) => (
                            <TableCell key={header.id} align={header.align}>
                                {flatten(row, )[header.id]}
                            </TableCell>
                        ))}
                        <TableCell>
                            {showIconButtons && <Stack direction="row">
                                <Tooltip title="Anzeigen">
                                    <IconButton onClick={() => onShow(row)}>
                                        <VisibilityIcon/>
                                    </IconButton>
                                </Tooltip>
                                <Tooltip title="Bearbeiten">
                                    <IconButton onClick={() => onEdit(row)}> <EditNoteIcon/>
                                    </IconButton>
                                </Tooltip>
                            </Stack>}
                        </TableCell>
                    </TableRow>
                ))}
            </>
        )

    return (
        <Card sx={{boxShadow: 3}}>
            {title && (<h3 style={{margin:"16px"}}>{title}</h3>)}
            <TableContainer component={Paper}>
                <Table sx={{minWidth: 650}} aria-label="simple table">
                    <TableHead>
                        <TableRow>
                            {headers.map((header) => (
                                <TableCell
                                    key={header.id}
                                    align={header.align}
                                    sx={{fontWeight: 'bold'}}
                                >
                                    <TableSortLabel
                                        active={orderBy === header.id}
                                        direction={orderBy === header.id ? orderDirection : 'asc'}
                                        onClick={() => handleSort(header.id)}
                                    >
                                        {header.label}
                                    </TableSortLabel>
                                </TableCell>
                            ))}
                            <TableCell></TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {tableContent}
                    </TableBody>
                </Table>
            </TableContainer>
        </Card>
    );
}
CrmTable.propTypes = {
    title: PropTypes.string,
    idField: PropTypes.string.isRequired,
    data: PropTypes.array.isRequired,
    headers: PropTypes.array.isRequired,
    onEdit: PropTypes.func,
    onShow: PropTypes.func,
    showIconButtons: PropTypes.bool
};

CrmTable.defaultProps = {
    showIconButtons: true
};
