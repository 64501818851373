import React from 'react';
import {CrmTable} from "../index";
import {Box, Button, CircularProgress, Stack} from "@mui/material";
import {useNavigate} from "react-router-dom";
import {useQuery} from "@tanstack/react-query";
import {LeadDataService} from "../../data";
import {format} from 'date-fns';


const LeadsOverview = () => {
    const navigate = useNavigate();
    const {isPending, error, data} = useQuery(
        {
            queryKey: ['leads'],
            queryFn: async () =>
                (await LeadDataService.fetchAll()).data.map(lead => ({
                    company: lead.company?.name,
                    contact: lead.contact?.name,
                    id: lead.id,
                    shortDescription: lead.shortDescription,
                    description: lead.description,
                    dateEntered: lead.dateEntered && format(lead.dateEntered, "HH:mm dd.MM.yyyy"),
                    dateModified: lead.dateModified && format(lead.dateModified, "HH:mm dd.MM.yyyy"),
                    leadSource: lead.leadSource.caption,
                    leadStatus: lead.leadStatus.caption
                }))
        });

    const headers = [
        {id: 'shortDescription', label: 'Titel', align: 'left'},
        {id: 'company', label: 'Firma', align: 'left'},
        {id: 'contact', label: 'Kontakt', align: 'left'},
        {id: 'leadSource', label: 'Quelle', align: 'left'},
        {id: 'leadStatus', label: 'Status', align: 'left'},
        {id: 'dateEntered', label: 'Erfasst am', align: 'left'},
        {id: 'dateModified', label: 'Zuletzt bearbeitet', align: 'left'},
    ];

    const handleEdit = (data) => {
        navigate("/leads/edit/" + data.id);
    }
    const handleShow = (data) => {
        navigate("/leads/show/" + data.id);
        return data;
    }

    let content;

    if (isPending)
        content = (<Box sx={{display: 'flex'}}>
            <CircularProgress/>
        </Box>);
    else if (error)
        content = (<p>{error.message}</p>);
    else
        content = (
            <div className={"table-container"}>
                <div className={"table-container-item"}>
                    <CrmTable
                        idField={"id"}
                        data={data}
                        onEdit={handleEdit}
                        onShow={handleShow}
                        headers={headers}/>
                </div>
            </div>
        )
    return (
        <Stack direction={"column"} alignItems={"flex-start"} spacing={2}>
            <h1>Leadübersicht</h1>
            <Button variant="contained" onClick={() => {
                navigate("new")
            }}>
                Neuer Lead
            </Button>
            {content}
        </Stack>
    );
}

export default LeadsOverview;
