import {useQuery} from "@tanstack/react-query";
import {LeadDataService} from "../../data";
import {useParams} from "react-router-dom";
import React, {useState} from "react";
import {
    Box,
    Button,
    CircularProgress,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    Grid,
    Typography
} from "@mui/material";
import JsonView from "@uiw/react-json-view";
import {AttributeDisplay} from "../../components/Controls";
import formateDate from "../../components/Controls/formateDate";


const LeadDetail = () => {
    const {leadId} = useParams();
    const [dialogOpen, setDialogOpen] = useState(false);
    const [dialogTitle, setDialogTitle] = useState(null);
    const [dialogContent, setDialogContent] = useState(null);

    const {data: leadData, error, isLoading} = useQuery({
        queryKey: [leadId],
        queryFn: async () => (await LeadDataService.getById(leadId)).data,
    });

    const handleShowDebug = () => {
        setDialogTitle("Debug");
        setDialogOpen(true);
        setDialogContent(<JsonView value={leadData}/>);
    };

    const handleClose = () => {
        setDialogTitle(null);
        setDialogOpen(false);
        setDialogContent(null);
    };

    if (isLoading) return <Box sx={{display: 'flex'}}>
        <CircularProgress/>
    </Box>;

    if (error) return <div>Fehler: {error.message}</div>;

    const DetailsDialog = () => {
        return (
            <Dialog open={dialogOpen} onClose={handleClose} fullWidth={true} maxWidth={"lg"}>
                <DialogTitle>{dialogTitle}</DialogTitle>
                <DialogContent>
                    <div style={{padding: "0.5em 0"}}>
                        {dialogContent}
                    </div>
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleClose}>Close</Button>
                </DialogActions>
            </Dialog>
        );
    };

    return (
        <>
            <h1>{leadData.shortDescription}</h1>

            <Grid container xs={8}>
                <Grid item xs={12} md={6}>
                    <AttributeDisplay
                        attr="Eingang"
                        value={formateDate(leadData.dateEntered ? leadData.dateEntered : '')}
                    />
                </Grid>
                <Grid item xs={12} md={6}>
                    <AttributeDisplay
                        attr="Letzte Bearbeitung"
                        value={formateDate(leadData.dateModified ? leadData.dateModified : '')}
                    />
                </Grid>
                <Grid item xs={6}>
                    <AttributeDisplay
                        attr="Quelle"
                        value={leadData.leadSource ? leadData.leadSource.caption : ''}
                    />
                </Grid>
                <Grid item xs={6}>
                    <AttributeDisplay
                        attr="Status"
                        value={leadData.leadStatus ? leadData.leadStatus.caption : ''}
                    />
                </Grid>
                <Grid item xs={12}>
                    <Typography style={{fontWeight:"bold"}}>Beschreibung</Typography>
                    <Typography variant="body1">
                        {(""+leadData.description).split("\n").map(line => (<p>{line}</p>))}
                    </Typography>
                </Grid>
            </Grid>

            {/*<Button onClick={handleShowDebug}>Show Data</Button>*/}
            <DetailsDialog/>
        </>
    );
};


export default LeadDetail;
